const heatpumpData = [
    {
        site: "Akshardham Mandir (3 Sites), Delhi",
        application: "Hot Water for Bathrooms",
        heatingCapacity: "12.5 kW x 3",
        powerInput: "3.7 kW x 3",
        extraText: "Integrated with existing solar panels",
        imagePath: "/machines-images/Heat Pump/Property/akshardham-new.jpeg"
    },
    {
        site: "Hotel The Maurya, Delhi",
        application: "Jacuzzi Water Heating",
        heatingCapacity: "12.5 kW x 2",
        powerInput: "3.7 kW x 2",
        extraText: "",
        imagePath: "/machines-images/Heat Pump/Property/maurya.jpeg"
    },
    {
        site: "RIMC, Dehradun",
        application: "Hot Water for Hostels",
        heatingCapacity: "51 kW x 3",
        powerInput: "13 kW x 3",
        extraText: "",
        imagePath: "/machines-images/Heat Pump/Machine/Dehradun_Heat_Pump.jpeg"
    },
    {
        site: "NIS, Patiala, Punjab",
        application: "Swimming Pool Heating",
        heatingCapacity: "120 kW x 3, 51 kW x 2",
        powerInput: "30 kW x 3, 13 kW x 2",
        extraText: "",
        imagePath: "/machines-images/Heat Pump/Property/NIS_patiala.jpeg"
    },
    {
        site: "Rishihood University, Sonipat, Haryana",
        application: "Hot Water for Bathrooms",
        heatingCapacity: "51 kW x 6",
        powerInput: "13 kW x 6",
        extraText: "",
        imagePath: "/machines-images/Heat Pump/Machine/Heat Pumps-Rishihood Univ.jpg"
    },
    {
        site: "Niravi Hospitality, Jaipur",
        application: "Hot Water for Bathrooms",
        heatingCapacity: "21 kW",
        powerInput: "6 kW",
        extraText: "",
        imagePath: "/machines-images/Heat Pump/Property/niravi_jaipur.jpg"
    },
    {
        site: "Hotel Palm D'or, Delhi",
        application: "Hot Water for Bathrooms",
        heatingCapacity: "21 kW",
        powerInput: "6 kW",
        extraText: "",
        imagePath: "/machines-images/Heat Pump/Property/palmdor.webp"
    },
    {
        site: "Hotel The Pamposh, Delhi",
        application: "Hot Water for Bathrooms",
        heatingCapacity: "12.5 kW x 2",
        powerInput: "3.7 kW x 2",
        extraText: "Integrated with existing solar panels",
        imagePath: "/machines-images/Heat Pump/Property/pamposh.jpeg"
    },
    // {
    //     site: "Hotel Omega Residency, Delhi",
    //     application: "Hot Water for Bathrooms",
    //     heatingCapacity: "51 kW",
    //     powerInput: "13 kW",
    //     extraText: "Integrated with existing solar panels",
    //     imagePath: "/machines-images/Heat Pump/Property/omega_residency.jpg"
    // },
    {
        site: "Hotel Te, Delhi",
        application: "Hot Water for Bathrooms",
        heatingCapacity: "51 kW",
        powerInput: "13 kW",
        extraText: "",
        imagePath: "/machines-images/Heat Pump/Property/hotel_te.jpeg"
    },
    {
        site: "Swami Parmanand Prakritik Chikitsalaya, Delhi",
        application: "Hot Water for Therapy",
        heatingCapacity: "51 kW",
        powerInput: "13 kW",
        extraText: "",
        imagePath: "/machines-images/Heat Pump/Property/swami_parmanand.jpeg"
    },
    {
        site: "JSL, Delhi",
        application: "Swimming Pool Heating",
        heatingCapacity: "51 kW x 2",
        powerInput: "13 kW x 2",
        extraText: "",
        imagePath: "/machines-images/Heat Pump/Property/jsl.jpg"
    },
    {
        site: "Wynberg Allen Senior School, Mussoorie",
        application: "Hot Water for Bathrooms",
        heatingCapacity: "34 kW x 2",
        powerInput: "9 kW x 2",
        extraText: "Integrated With Waste Heat  Recovery From Power Generator",
        installation: "Boys Hostel",
        imagePath: "/machines-images/Heat Pump/Property/wynberg.png"
    },
    {
        site: "Wynberg Allen Senior School, Mussoorie",
        application: "Hot Water for Bathrooms",
        heatingCapacity: "(34 kW x 2) + (51 kW)",
        powerInput: "(9 kW x 2) + (13 kW)",
        extraText: "Integrated With Waste Heat Recovery From Power Generator",
        installation: "Girls Hostel",
        imagePath: "/machines-images/Heat Pump/Property/wynberg.png"
    },
    {
        site: "Wynberg Allen Senior School, Mussoorie",
        application: "Swimming Pool Heating",
        heatingCapacity: "51 kW x 4",
        powerInput: "13 kW x 4",
        extraText: "",
        installation: "",
        imagePath: "/machines-images/Heat Pump/Property/wynberg.png"
    },
    {
        site: "Hotel Dunsvirk Court, Mussoorie",
        application: "Hot Water for Bathrooms",
        heatingCapacity: "51 kW x 4",
        powerInput: "13 kW x 4",
        extraText: "",
        imagePath: "/machines-images/Heat Pump/Property/dunsvirk.jpeg"
    },
    {
        site: "Guru Nanak Fifth Centenary School, Mussoorie",
        application: "Hot Water for Bathrooms",
        heatingCapacity: "51 kW",
        powerInput: "13 kW",
        extraText: "",
        installation: "Junior Hostel",
        imagePath: "/machines-images/Heat Pump/Property/guru_nanak_fifth.png"
    },
    {
        site: "Guru Nanak Fifth Centenary School, Mussoorie",
        application: "Hot Water for Bathrooms",
        heatingCapacity: "51 kW",
        powerInput: "13 kW",
        extraText: "",
        installation: "Boys Hostel",
        imagePath: "/machines-images/Heat Pump/Property/guru_nanak_fifth.png"
    },
    {
        site: "Guru Nanak Fifth Centenary School, Mussoorie",
        application: "Hot Water for Bathrooms",
        heatingCapacity: "51 kW",
        powerInput: "13 kW",
        extraText: "",
        installation: "Girls Hostel",
        imagePath: "/machines-images/Heat Pump/Property/guru_nanak_fifth.png"
    },
    {
        site: "Guru Nanak Fifth Centenary School, Mussoorie",
        application: "Swimming Pool Heating",
        heatingCapacity: "51 kW",
        powerInput: "13 kW",
        extraText: "",
        installation: "",
        imagePath: "/machines-images/Heat Pump/Property/guru_nanak_fifth.png"
    },
    {
        site: "Welham Boys School, Dehradun",
        application: "Hot Water for Bathrooms",
        heatingCapacity: "21 kW x 2",
        powerInput: "6 kW x 2",
        extraText: "Integrated With Existing Solar Panels",
        imagePath: "/machines-images/Heat Pump/Property/welham.jpeg"
    },
    {
        site: "Hotel Hyphen Grand, Haridwar",
        application: "Hot Water for Bathrooms",
        heatingCapacity: "51 kW x 3",
        powerInput: "13 kW x 3",
        extraText: "",
        imagePath: "/machines-images/Heat Pump/Property/hyphenhari.jpg"
    },
    {
        site: "Hotel Hyphen Premier, Meerut",
        application: "Hot Water for Bathrooms",
        heatingCapacity: "34 kW x 3",
        powerInput: "9 kW x 3",
        extraText: "",
        imagePath: "/machines-images/Heat Pump/Property/supertech1.jpg"
    },
    {
        site: "Father Agnel School,  Noida",
        application: "Hot Water for Bathrooms",
        heatingCapacity: "12.5 kW x 2, 21 kW x 1",
        powerInput: "3.75 kW x 2, 6 kW x 1",
        extraText: "",
        imagePath: "/machines-images/Heat Pump/Property/faschool1.jpg"
    },
    {
        site: "Hotel Sarovar Portico, Vrindavan",
        application: "Hot Water for Bathrooms",
        heatingCapacity: "34 kW",
        powerInput: "9 kW",
        extraText: "",
        imagePath: "/machines-images/Heat Pump/Property/sarovar.avif"
    },
    {
        site: "Jagatguru Kripalu Parishat, Vrindavan",
        application: "Hot Water for Bathrooms",
        heatingCapacity: "21 kW x 2",
        powerInput: "6 kW x 2",
        extraText: "",
        imagePath: "/machines-images/Heat Pump/Property/jagadguru.jpeg"
    },
    {
        site: "Jain Mandir, Hastinapur",
        application: "Hot Water for Bathrooms",
        heatingCapacity: "34 kW x 2",
        powerInput: "9 kW x 2",
        extraText: "",
        imagePath: "/machines-images/Heat Pump/Property/jain_mandir.jpeg"
    },
    {
        site: "Hotel Banaras Haveli, Varanasi",
        application: "Hot Water for Bathrooms",
        heatingCapacity: "12.5 kW x 2",
        powerInput: "3.75 kW x 2",
        extraText: "",
        imagePath: "/machines-images/Heat Pump/Property/banaras.jpeg"
    },
    {
        site: "Hotel Silver Leaf, Punjab",
        application: "Hot Water for Bathrooms",
        heatingCapacity: "21 kW x 2",
        powerInput: "6 kW x 2",
        extraText: "",
        imagePath: "/machines-images/Heat Pump/Property/silver_leaf.avif"
    },
    {
        site: "Hotel Sarovar Portico, Jalandhar",
        application: "Hot Water for Bathrooms",
        heatingCapacity: "51 kW x 2",
        powerInput: "13 kW x 2",
        extraText: "",
        imagePath: "/machines-images/Heat Pump/Property/sarovar_jalandhar.avif"
    },
    {
        site: "Gateway Group, Sonipat",
        application: "Hot Water for Boys Hostel",
        heatingCapacity: "21 kW x 2",
        powerInput: "6 kW x 2",
        extraText: "",
        installation: "",
        imagePath: "/machines-images/Heat Pump/Property/gateway1.jpg"
    },
    {
        site: "Gateway Group, Sonipat",
        application: "Hot Water for Girls Hostel",
        heatingCapacity: "21 kW x 2",
        powerInput: "6 kW x 2",
        extraText: "",
        installation: "",
        imagePath: "/machines-images/Heat Pump/Property/gateway1.jpg"
    },
    {
        site: "Suryadeep Hospital, Gurugram",
        application: "Hot Water for Bathrooms",
        heatingCapacity: "12.5 kW x 2",
        powerInput: "3.75 kW x 2",
        extraText: "Integrated With Existing Solar Panels",
        imagePath: "/machines-images/Heat Pump/Property/suryadeep.jpeg"
    },
    {
        site: "Utsav Resort, Sonipat",
        application: "Hot Water for Bathrooms",
        heatingCapacity: "21 kW x 2",
        powerInput: "6 kW x 2",
        extraText: "",
        imagePath: "/machines-images/Heat Pump/Property/utsavresort1.jpg"
    },
    {
        site: "Hotel The Vivaan, Karnal",
        application: "Hot Water for Bathrooms",
        heatingCapacity: "21 kW x 2",
        powerInput: "6 kW x 2",
        extraText: "",
        imagePath: "/machines-images/Heat Pump/Property/vivaan.jpeg"
    },
    {
        site: "Hotel Country-Inn, Bhiwadi",
        application: "Hot Water for Bathrooms",
        heatingCapacity: "34 kW x 2",
        powerInput: "9 kW x 2",
        extraText: "",
        imagePath: "/machines-images/Heat Pump/Property/country_inn.jpeg"
    },
    {
        site: "RK Marble, Kishangarh",
        application: "Hot Water for Bathrooms",
        heatingCapacity: "34 kW",
        powerInput: "9 kW",
        extraText: "Integrated With Existing Solar Panels",
        imagePath: "/machines-images/Heat Pump/Property/rkm.jpg"
    },
    {
        site: "Hotel Devgarh Mahal, Devgarh",
        application: "Hot Water for Bathrooms",
        heatingCapacity: "21 kW",
        powerInput: "6 kW",
        extraText: "",
        imagePath: "/machines-images/Heat Pump/Property/devgarh.jpeg"
    },
    {
        site: "Hotel Sarovar Hometel, Jaipur ",
        application: "Hot Water for Bathrooms",
        heatingCapacity: "34 kW x 2",
        powerInput: "9 kW x 2",
        extraText: "Integrated With Existing Solar Panels",
        imagePath: "/machines-images/Heat Pump/Property/sarovarjaipur1.jpg"
    },
    {
        site: "Hotel Devgarh Mahal, Devgarh",
        application: "Hot Water for Bathrooms",
        heatingCapacity: "51 kW x 2",
        powerInput: "13 kW x 2",
        extraText: "",
        imagePath: "/machines-images/Heat Pump/Property/devgarh.jpeg"
    },
    {
        site: "Hotel Umaid Bhawan, Jaipur",
        application: "Hot Water for Bathrooms",
        heatingCapacity: "51 kW",
        powerInput: "13 kW",
        extraText: "",
        installation: "1",
        imagePath: "/machines-images/Heat Pump/Property/umaid.jpeg"
    },
    // {
    //     site: "Hotel Umaid Bhawan, Jaipur",
    //     application: "Hot Water for Bathrooms",
    //     heatingCapacity: "21 kW",
    //     powerInput: "6 kW",
    //     extraText: "",
    //     installation: "2",
    //     imagePath: "/machines-images/Heat Pump/Property/umaid.jpeg"
    // },
    {
        site: "Hotel Umaid Mahal, Jaipur",
        application: "Hot Water for Bathrooms",
        heatingCapacity: "34 kW",
        powerInput: "9 kW",
        extraText: "",
        imagePath: "/machines-images/Heat Pump/Property/umaid.jpeg"
    },
    {
        site: "Hotel Umaid Residency, Jaipur",
        application: "Hot Water for Bathrooms",
        heatingCapacity: "51 kW x 2",
        powerInput: "13 kW x 2",
        extraText: "",
        imagePath: "/machines-images/Heat Pump/Property/umaid_residency.jpeg"
    },
    {
        site: "Hotel Umaid Haveli, Jaipur",
        application: "Hot Water for Bathrooms",
        heatingCapacity: "51 + 34 + 34 kW ",
        powerInput: "13 + 9 + 9 kW",
        extraText: "",
        imagePath: "/machines-images/Heat Pump/Property/umaid_haveli_new.jpeg"
    },
    {
        site: "Vijay Palace, Jaipur",
        application: "Hot Water for Bathrooms",
        heatingCapacity: "21 kW",
        powerInput: "6 kW",
        extraText: "",
        imagePath: "/machines-images/Heat Pump/Property/vijay_palace.jfif"
    },
    {
        site: "Hotel Khasbagh, Jaipur",
        application: "Hot Water for Bathrooms",
        heatingCapacity: "21 kW",
        powerInput: "6 kW",
        extraText: "Integrated With Existing Solar Panels",
        imagePath: "/machines-images/Heat Pump/Property/khasbagh.jpeg"
    },
    {
        site: "Hotel Citrus, Jaipur",
        application: "Hot Water for Bathrooms",
        heatingCapacity: "21 kW x 2",
        powerInput: "6 kW x 2",
        extraText: "",
        imagePath: "/machines-images/Heat Pump/Property/citrus.jpeg"
    },
    {
        site: "Hotel Sahpura House, Jaipur",
        application: "Hot Water for Bathrooms",
        heatingCapacity: "21 kW x 4",
        powerInput: "6 kW x 4",
        extraText: "Integrated With Existing Solar Panels",
        imagePath: "/machines-images/Heat Pump/Property/sahpura_house.jpeg"
    },
    {
        site: "Hotel Devraj Nivas, Jaipur",
        application: "Hot Water for Bathrooms",
        heatingCapacity: "21 kW",
        powerInput: "6 kW",
        extraText: "Integrated With Existing Solar Panels",
        imagePath: "/machines-images/Heat Pump/Property/devrajniwasf.jpg"
    }
]

export default heatpumpData;